import logo from "../assets/img/logo.webp"


export default function Footer({history}) {
    return (
        <div className={`w-full  bg-[#F8F8F8] `}>
            <div
                className={`w-full px-4 s:px-6 sm:px-8 lg:px-12 xl:px-16 max-w-7xl mx-auto bg-[#F8F8F8]  md:flex flex-row justify-center items-start py-6 pb
                -16`}>
                <div
                    className={`py-2  w-full max-w-4xl xl:max-w-5xl flex flex-col space-y-4 sm:space-y-0 sm:flex-row justify-between items-start`}>
                    <div className={`sm:w-1/2`}>
                        <img className="w-36 items-center" src={logo} alt="logo"/>
                        <p className={`mt-5 text-xs sm:w-4/5 text-[#767676] leading-relaxed`}>Compania noastră de
                            închirieri oferă clienților posibilitatea de a accesa o gamă largă de cofraje Doka și
                            sisteme complete pentru cofrarea tuturor elementelor de beton. Indiferent de dimensiunea și
                            complexitatea proiectului dumneavoastră, veți găsi aici soluția potrivită pentru a vă
                            îndeplini nevoile. Echipa noastră experimentată poate oferi consultanță și asistență în
                            alegerea produselor potrivite, iar serviciul nostru de livrare asigură un proces eficient și
                            simplu de livrare într-un timp foarte scurt a echipamentelor pe șantierul dumneavoastră.</p>

                    </div>

                    <div itemScope itemType="http://schema.org/Organization" className={`flex flex-row sm:w-1/2`}>
                        <div className={`w-1/2`}>
                            <h4 className={`text-sm font-bold`}>Infomații</h4>
                            <h4 className={`uppercase text-xs mt-4 text-secondary font-bold`}>Adresă</h4>
                            <h4 itemProp="company" className={`uppercase mt-1 text-xs text-secondary hidden`}>GD-HOLZ</h4>
                            <div className={`flex flex-col text-[#767676] text-xs `}>
                                <span>Oradea, Bihor, România</span>
                                <span>Arad, Arad, România</span>
                            </div>
                            <h4 className={`uppercase text-xs mt-3 text-secondary font-bold`}>telefon</h4>
                            <a itemProp="tel" href="tel:+40774956974" className={`text-[#767676] text-xs mt-1`}>+40 774
                                956 974</a>
                            <h4 className={`uppercase text-xs mt-3 text-secondary font-bold`}>email</h4>
                            <a itemProp="email" href="mailto:office@gd-holz.ro"
                               className={`text-[#767676] text-xs mt-1`}>office@gd-holz.ro</a>
                        </div>
                        <div className={`w-1/2`}>
                            <h4 className={`text-sm font-bold`}>Link-uri utile</h4>
                            <ul className={`text-[#767676] text-xs mt-4 space-y-3`}>
                                <li><a href="/politica-si-confidentialitate">Politica de confidentialitate </a></li>
                                <li><a href="/termeni-si-conditii">Termeni si conditii</a></li>
                            </ul>
                            <div className={`flex flex-row space-x-3 mt-6`}>
                                <a href="https://www.facebook.com/people/Închirieri-panouri-Doka-Bihor/100083006298518/"
                                   target="_blank" rel="noopener noreferrer"
                                   className={`bg-[#252525] text-[#F6C10D] hover:transition-all duration-300 hover:text-white hover:bg-[#F6C10D] flex flex-row justify-center items-center pt-1 px-1 rounded-md`}>
                                    <svg className={`w-5 h-5 mt-1`} viewBox="0 0 16 22" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <g filter="url(#filter0_d_7_1620)">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M11.9671 5.29313H9.50842V3.3478C9.50842 2.81089 9.94901 2.86147 10.4919 2.86147H11.4754V0.42981H9.50842C8.72592 0.42981 7.97547 0.73724 7.42216 1.28447C6.86885 1.8317 6.55801 2.5739 6.55801 3.3478V5.29313H4.59106V7.72479H6.55801V13.0744H9.50842V7.72479H10.9836L11.9671 5.29313Z"
                                                  fill="white"/>
                                        </g>
                                        <defs>
                                            <filter id="filter0_d_7_1620" x="0.591064" y="0.42981" width="15.376"
                                                    height="20.6447" filterUnits="userSpaceOnUse"
                                                    color-interpolation-filters="sRGB">
                                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                                <feColorMatrix in="SourceAlpha" type="matrix"
                                                               values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                               result="hardAlpha"/>
                                                <feOffset dy="4"/>
                                                <feGaussianBlur stdDeviation="2"/>
                                                <feComposite in2="hardAlpha" operator="out"/>
                                                <feColorMatrix type="matrix"
                                                               values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                                <feBlend mode="normal" in2="BackgroundImageFix"
                                                         result="effect1_dropShadow_7_1620"/>
                                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_7_1620"
                                                         result="shape"/>
                                            </filter>
                                        </defs>
                                    </svg>
                                    {/*<svg*/}
                                    {/*    xmlns="http://www.w3.org/2000/svg"*/}
                                    {/*    className={`w-6 h-6`}*/}
                                    {/*    viewBox="-595.2 -595.2 3110.4 3110.4"*/}
                                    {/*>*/}
                                    {/*    <path*/}
                                    {/*        fillRule="evenodd"*/}
                                    {/*        d="M1168.737 487.897c44.672-41.401 113.824-36.889 118.9-36.663l289.354-.113 6.317-417.504L1539.65 22.9C1511.675 16.02 1426.053 0 1237.324 0 901.268 0 675.425 235.206 675.425 585.137v93.97H337v451.234h338.425V1920h451.234v-789.66h356.7l62.045-451.233H1126.66v-69.152c0-54.937 14.214-96.112 42.078-122.058"*/}
                                    {/*    />*/}
                                    {/*</svg>*/}
                                </a>
                                {/*<button disabled={true} className={`bg-[#252525] flex flex-row justify-center items-center pt-1 px-[9px] rounded-md`}>*/}
                                {/*    <svg className={`w-2.5 h-2.5 -mt-1`} viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                                {/*        <path d="M11.6524 1.59539C11.2265 1.79055 10.687 1.97373 10.2036 2.03462C10.6952 1.73315 11.4752 1.10524 11.6524 0.537231C11.1931 0.816245 10.3625 1.11423 9.82059 1.22204C9.38786 0.747864 8.77064 0.537231 8.08678 0.537231C6.77314 0.537231 5.85698 1.68773 5.85698 3.03288V4.03114C3.92516 4.03114 2.04164 2.51029 0.869507 1.03636C0.657566 1.40786 0.546295 1.83146 0.547375 2.26272C0.547375 3.17064 1.35439 4.09204 1.99334 4.53027C1.6036 4.51779 0.865643 4.21033 0.544478 4.03114V4.05959C0.544478 5.24103 1.34667 6.04313 2.4338 6.26675C2.2353 6.32315 1.99334 6.52679 1.06221 6.52679C1.36453 7.49261 2.88439 8.00322 3.92516 8.02418C3.1109 8.67655 1.65914 9.02244 0.544478 9.02244C0.351779 9.02244 0.247461 9.03342 0.0615234 9.01096C1.1134 9.69976 2.58255 10.0207 3.92516 10.0207C8.29928 10.0207 10.6865 6.56772 10.6865 3.34733C10.6865 3.24152 10.6831 2.88714 10.6778 2.78332C11.1453 2.44291 11.3346 2.08653 11.6524 1.59539" fill="white"/>*/}
                                {/*    </svg>*/}
                                {/*</button>*/}
                                {/*<button  disabled={true} className={`bg-[#252525] flex flex-row justify-center items-center pt-1 px-2 rounded-md`}>*/}
                                {/*    <svg  className={`w-3 h-3 -mt-1.5`} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                                {/*            <path d="M5.8 0H14.2C17.4 0 20 2.6 20 5.8V14.2C20 15.7383 19.3889 17.2135 18.3012 18.3012C17.2135 19.3889 15.7383 20 14.2 20H5.8C2.6 20 0 17.4 0 14.2V5.8C0 4.26174 0.61107 2.78649 1.69878 1.69878C2.78649 0.61107 4.26174 0 5.8 0M5.6 2C4.64522 2 3.72955 2.37928 3.05442 3.05442C2.37928 3.72955 2 4.64522 2 5.6V14.4C2 16.39 3.61 18 5.6 18H14.4C15.3548 18 16.2705 17.6207 16.9456 16.9456C17.6207 16.2705 18 15.3548 18 14.4V5.6C18 3.61 16.39 2 14.4 2H5.6ZM15.25 3.5C15.5815 3.5 15.8995 3.6317 16.1339 3.86612C16.3683 4.10054 16.5 4.41848 16.5 4.75C16.5 5.08152 16.3683 5.39946 16.1339 5.63388C15.8995 5.8683 15.5815 6 15.25 6C14.9185 6 14.6005 5.8683 14.3661 5.63388C14.1317 5.39946 14 5.08152 14 4.75C14 4.41848 14.1317 4.10054 14.3661 3.86612C14.6005 3.6317 14.9185 3.5 15.25 3.5ZM10 5C11.3261 5 12.5979 5.52678 13.5355 6.46447C14.4732 7.40215 15 8.67392 15 10C15 11.3261 14.4732 12.5979 13.5355 13.5355C12.5979 14.4732 11.3261 15 10 15C8.67392 15 7.40215 14.4732 6.46447 13.5355C5.52678 12.5979 5 11.3261 5 10C5 8.67392 5.52678 7.40215 6.46447 6.46447C7.40215 5.52678 8.67392 5 10 5M10 7C9.20435 7 8.44129 7.31607 7.87868 7.87868C7.31607 8.44129 7 9.20435 7 10C7 10.7956 7.31607 11.5587 7.87868 12.1213C8.44129 12.6839 9.20435 13 10 13C10.7956 13 11.5587 12.6839 12.1213 12.1213C12.6839 11.5587 13 10.7956 13 10C13 9.20435 12.6839 8.44129 12.1213 7.87868C11.5587 7.31607 10.7956 7 10 7Z" fill="#F8F8F8"/>*/}
                                {/*        </svg>*/}
                                {/*</button>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}