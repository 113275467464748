import logo from "../assets/img/logo.webp"
import Footer from "./Footer";
import {slide as MenuBurger} from 'react-burger-menu'
import burger_menu from "../assets/img/hamburger-menu.webp"
import close from "../assets/img/close.webp"
import {Menu, Transition} from '@headlessui/react'
import {Fragment, useState} from "react";



export default function Terms() {
    const onClose = (e) => {
        setOpenMenu(false)
    }

    const onOpenMenu = (e) => {
        setOpenMenu(e.isOpen)
    }
    const [openMenu, setOpenMenu] = useState(false)
    return (
        <div>
            <div className="fixed top-0 w-screen z-20 border-[#F6C10D] bg-white w-full border-b-2">
                <div
                    className={`z-10 px-4 s:px-6 sm:px-8 lg:px-12 xl:px-16 max-w-7xl mx-auto hidden lg:flex w-full flex-row justify-between justify-center items-center py-6`}>
                    <img className="w-40" src={logo} alt="Logo"/>
                    <div
                        className={`flex flex-row transition-all duration-300 items-center space-x-6 lg:space-x-8 md:text-sm`}>
                        <h2 className="duration-300 hover:text-[#F6C10D]"><a href="/#ofertapret">Solicită oferta de
                            preț</a></h2>
                        <h2 className="duration-300 hover:text-[#F6C10D]"><a href="/#caracteristici">Caracteristici</a>
                        </h2>
                        <h2 className="duration-300 hover:text-[#F6C10D]"><a href="/#galerie">Galerie foto</a></h2>
                        <h2 className="duration-300 hover:text-[#F6C10D]"><a href="/#testimoniale">Testimoniale</a></h2>

                        <div className=" top-16 w-42 text-right">
                            <Menu as="div" className="relative inline-block text-left">
                                <div className="z-0">
                                    <Menu.Button
                                        className="relative z-10 inline-flex w-full border-[#F6C10D] text-[#F6C10D] hover:transition-all duration-300 hover:text-white hover:bg-[#F6C10D] border-2 py-2.5 px-4 shadow-[0_35px_60px_-15px_rgba(246, 193, 13,0.3)] rounded-2xl justify-center items-center bg-opacity-20 px-4 py-2 text-sm font-bold">
                                        <p className="">Contactează-ne</p>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             strokeWidth={2} stroke="currentColor"
                                             className="w-4 h-4 ml-1.5">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"/>
                                        </svg>
                                    </Menu.Button>
                                </div>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items
                                        className="absolute right-0 mt-2 w-56 origin-top-right divide-y border-[#F6C10D] border-2 divide-gray-100 rounded-xl bg-white shadow-xl ocus:outline-none">
                                        <div className="px-1 py-1 ">
                                            <Menu.Item>
                                                <a href="tel:40774956974"
                                                   className="flex flex-row items-center p-2 px-4 rounded-3xl font-semibold text-primary">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                         viewBox="0 0 24 24" strokeWidth={1.5} stroke="#484744"
                                                         className="w-[1.1rem] h-[1.1rem]">
                                                        <path strokeLinecap="round" strokeLinejoin="round"
                                                              d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"/>
                                                    </svg>
                                                    <h2 className="text-sm text-[#484744] ml-2">Nr. de telefon</h2>
                                                </a>
                                            </Menu.Item>
                                            <Menu.Item>
                                                <a href="https://api.whatsapp.com/send?phone=40774956974"
                                                   className={`flex flex-row items-center p-2 px-4 rounded-3xl font-semibold text-white`}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="w-4 h-4"
                                                        fill="#25D366"
                                                        version="1.1"
                                                        viewBox="0 0 308 308"
                                                        xmlSpace="preserve"
                                                    >
                                                        <g>
                                                            <path
                                                                d="M227.904 176.981c-.6-.288-23.054-11.345-27.044-12.781-1.629-.585-3.374-1.156-5.23-1.156-3.032 0-5.579 1.511-7.563 4.479-2.243 3.334-9.033 11.271-11.131 13.642-.274.313-.648.687-.872.687-.201 0-3.676-1.431-4.728-1.888-24.087-10.463-42.37-35.624-44.877-39.867-.358-.61-.373-.887-.376-.887.088-.323.898-1.135 1.316-1.554 1.223-1.21 2.548-2.805 3.83-4.348a140.77 140.77 0 011.812-2.153c1.86-2.164 2.688-3.844 3.648-5.79l.503-1.011c2.344-4.657.342-8.587-.305-9.856-.531-1.062-10.012-23.944-11.02-26.348-2.424-5.801-5.627-8.502-10.078-8.502-.413 0 0 0-1.732.073-2.109.089-13.594 1.601-18.672 4.802C90 87.918 80.89 98.74 80.89 117.772c0 17.129 10.87 33.302 15.537 39.453.116.155.329.47.638.922 17.873 26.102 40.154 45.446 62.741 54.469 21.745 8.686 32.042 9.69 37.896 9.69h.001c2.46 0 4.429-.193 6.166-.364l1.102-.105c7.512-.666 24.02-9.22 27.775-19.655 2.958-8.219 3.738-17.199 1.77-20.458-1.348-2.216-3.671-3.331-6.612-4.743z"/>
                                                            <path
                                                                d="M156.734 0C73.318 0 5.454 67.354 5.454 150.143c0 26.777 7.166 52.988 20.741 75.928L.212 302.716a3.998 3.998 0 004.999 5.096l79.92-25.396c21.87 11.685 46.588 17.853 71.604 17.853C240.143 300.27 308 232.923 308 150.143 308 67.354 240.143 0 156.734 0zm0 268.994c-23.539 0-46.338-6.797-65.936-19.657a3.996 3.996 0 00-3.406-.467l-40.035 12.726 12.924-38.129a4.002 4.002 0 00-.561-3.647c-14.924-20.392-22.813-44.485-22.813-69.677 0-65.543 53.754-118.867 119.826-118.867 66.064 0 119.812 53.324 119.812 118.867.001 65.535-53.746 118.851-119.811 118.851z"/>
                                                        </g>
                                                    </svg>
                                                    <span className="text-[#25D366] ml-2">Whatsapp</span>
                                                </a>
                                            </Menu.Item>
                                            <Menu.Item>
                                                <a href="mailto:office@gd-holz.ro"
                                                   className="flex flex-row items-center p-2 px-4 font-semibold text-primary">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                         viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                                                         className="w-5 h-5">
                                                        <path strokeLinecap="round"
                                                              d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25"/>
                                                    </svg>
                                                    <h2 className="text-sm ml-2">Adresă de email</h2>
                                                </a>
                                            </Menu.Item>
                                        </div>
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        </div>
                    </div>
                </div>

                <div className={`flex flex-row items-center z-10 lg:hidden`}>
                    <button className="ml-2" onClick={() => {
                        setOpenMenu(true)
                    }}><img className="w-10" src={burger_menu} alt="menu button"/></button>
                    <div className="-ml-12 -mt-16">
                        <MenuBurger className="" itemListElement="div" isOpen={openMenu} onStateChange={onOpenMenu}
                                    onClose={onClose} customBurgerIcon={false} customCrossIcon={false}>
                            <div className="relative bg-white h-full flex flex-col">
                                <button className="absolute right-4 top-4" onClick={() => {
                                    setOpenMenu(false)
                                }}>
                                    <img className="w-8" src={close} alt="menu button"/>
                                </button>
                                <img className="mx-auto pt-12 w-40" src={logo} alt="Logo"/>
                                <div className="px-6 flex flex-col justify-start mt-6">
                                    <button className="text-left" onClick={() => {
                                        setOpenMenu(false)
                                    }}>
                                        <a id="home" href="/#ofertapret" className="menu-item">Solicită oferta de
                                            preț</a>
                                    </button>
                                    <div className="w-full h-0.5 opacity-40 m-1 bg-[#F6C10D]"/>
                                    <button className="text-left" onClick={() => {
                                        setOpenMenu(false)
                                    }}>
                                        <a id="about" href="/#caracteristici" className="menu-item">Caracteristici</a>
                                    </button>
                                    <div className="w-full h-0.5 m-1 opacity-40 bg-[#F6C10D]"/>
                                    <button className="text-left" onClick={() => {
                                        setOpenMenu(false)
                                    }}>
                                        <a id="contact" href="/#galerie" className="menu-item">Galerie foto</a>
                                    </button>
                                    <div className="w-full h-0.5 m-1 opacity-40 bg-[#F6C10D]"/>
                                    <button className="text-left" onClick={() => {
                                        setOpenMenu(false)
                                    }}><a id="contact" href="/#testimoniale" className="menu-item">Testimoniale</a>
                                    </button>
                                    <a href="tel:40774956974"
                                       className="flex flex-row items-center justify-center border-[#484744] border-2 p-2.5 px-4 rounded-3xl font-semibold text-primary mt-6">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             strokeWidth={1.5} stroke="#484744" className="w-[1.1rem] h-[1.1rem]">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"/>
                                        </svg>
                                        <h2 className="text-sm text-[#484744] ml-2">Nr. de telefon</h2>
                                    </a>
                                    <a href="https://api.whatsapp.com/send?phone=40774956974"
                                       className={`flex flex-row items-center justify-center border-[#25D366] border-2 p-2.5 px-4 rounded-3xl font-semibold text-white mt-3`}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="w-4 h-4"
                                            fill="#25D366"
                                            version="1.1"
                                            viewBox="0 0 308 308"
                                            xmlSpace="preserve"
                                        >
                                            <g>
                                                <path
                                                    d="M227.904 176.981c-.6-.288-23.054-11.345-27.044-12.781-1.629-.585-3.374-1.156-5.23-1.156-3.032 0-5.579 1.511-7.563 4.479-2.243 3.334-9.033 11.271-11.131 13.642-.274.313-.648.687-.872.687-.201 0-3.676-1.431-4.728-1.888-24.087-10.463-42.37-35.624-44.877-39.867-.358-.61-.373-.887-.376-.887.088-.323.898-1.135 1.316-1.554 1.223-1.21 2.548-2.805 3.83-4.348a140.77 140.77 0 011.812-2.153c1.86-2.164 2.688-3.844 3.648-5.79l.503-1.011c2.344-4.657.342-8.587-.305-9.856-.531-1.062-10.012-23.944-11.02-26.348-2.424-5.801-5.627-8.502-10.078-8.502-.413 0 0 0-1.732.073-2.109.089-13.594 1.601-18.672 4.802C90 87.918 80.89 98.74 80.89 117.772c0 17.129 10.87 33.302 15.537 39.453.116.155.329.47.638.922 17.873 26.102 40.154 45.446 62.741 54.469 21.745 8.686 32.042 9.69 37.896 9.69h.001c2.46 0 4.429-.193 6.166-.364l1.102-.105c7.512-.666 24.02-9.22 27.775-19.655 2.958-8.219 3.738-17.199 1.77-20.458-1.348-2.216-3.671-3.331-6.612-4.743z"/>
                                                <path
                                                    d="M156.734 0C73.318 0 5.454 67.354 5.454 150.143c0 26.777 7.166 52.988 20.741 75.928L.212 302.716a3.998 3.998 0 004.999 5.096l79.92-25.396c21.87 11.685 46.588 17.853 71.604 17.853C240.143 300.27 308 232.923 308 150.143 308 67.354 240.143 0 156.734 0zm0 268.994c-23.539 0-46.338-6.797-65.936-19.657a3.996 3.996 0 00-3.406-.467l-40.035 12.726 12.924-38.129a4.002 4.002 0 00-.561-3.647c-14.924-20.392-22.813-44.485-22.813-69.677 0-65.543 53.754-118.867 119.826-118.867 66.064 0 119.812 53.324 119.812 118.867.001 65.535-53.746 118.851-119.811 118.851z"/>
                                            </g>
                                        </svg>
                                        <span className="text-sm text-[#25D366] ml-2">Whatsapp</span>
                                    </a>
                                    <a href="mailto:office@gd-holz.ro"
                                       className="flex flex-row justify-center border-[#F6C10D] border-2 items-center rounded-3xl p-2.5 px-4 font-semibold text-primary mt-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                            <path strokeLinecap="round"
                                                  d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25"/>
                                        </svg>
                                        <h2 className="text-sm ml-2">Adresă de email</h2>
                                    </a>
                                </div>
                            </div>
                        </MenuBurger>
                    </div>
                    <img className="w-40 mx-auto py-4" src={logo} alt="Logo"/>
                </div>
            </div>
         <div>
         <div className="{w-full  px-4 s:px-6  sm:px-8 lg:px-12 xl:px-16 max-w-7xl mx-auto mb-40 }">

      
        
<h2 className="font-helvetica text-primary text-center font-bold text-2xl  md:text-2xl  lg:text-2xl mt-32 lg:mt-44">TERMENI ȘI CONDIȚII</h2>
<h2 className="font-bold  text-secondary 2xl:text-lg mt-10 lg:mt-24">DISPOZIȚII GENERALE</h2>
<p className="mt-2 text-base text-secondary">Totalitatea conținutului utilizabil  și vizitabil accesând pagină de Internet
www.închirieri-panouri-doka-oradea.ro denumită  în continuare "site", reprezintă  proprietatea
<span className="font-bold"> G.D ROYAL RESIDENCE S.R.L</span>
</p>
<p  className="mt-2 text-base text-secondary">
Firma care administrează  site-ul www.închirieri-panouri-doka-oradea.ro este  G.D ROYAL RESIDENCE S.R.L , numită în continuare "Vânzător", înregistrată
 la Registrul Comerțului cu nr.J35/969/2022, având CUI 45752941
</p>
 <p className="mt-2 text-base text-secondary">
 Parcurgerea  și folosirea site-ului www.închirieri-panouri-doka-oradea.ro de către utilizator reprezintă acordul acestuia fără limitări sau rezerve a
     prezențelor Termeni și Condiții
</p>
 <p className="mt-2 text-base text-secondary">Denumit în cele ce urmează "Utilizator", "Client", "Cumpărător" acesta este orice persoană fizică sau juridică care folosește site-ul cu scopul de a se informa sau de a achiziționa
    produsele comercializate prin intermediul acestuia.Utilizatorul poate folosi site-ul doar în condițiile în care este de acord și respectă Termeni și Condițiile </p>

    <p className="mt-2 text-base text-secondary">Prezentul text reprezintă cunoașterea și obligativitatea închirieri Panouri Doka Oradea de a respectă legislația română în vigoare
</p>
    <p className="mt-2 text-base text-secondary">
    Prin însușirea prevederilor menționate în Termeni și Condiții, Utilizatorul ia la cunoștință și își asumă consecințele rezultate în urmă nerespectării prevederilor menționate.
     Pentru orice fel de nelămuriri legate de Termeni și Condiții,
     Închirieri Panouri Doka Oradea se pune la dispoziția dumneavoastră în timpul programului de lucru menționat în paginile produselor.
     Pentru sesizări se folosesc datele de contact aflate pe site la pagină de contact.


    </p><br/>
    <h2 className="font-bold mt-2 text-base text-secondary">POLITICA DE CONFIDENTIALITATE ȘI PROTECȚIA DATELOR</h2>
    <p className="mt-2 text-base text-secondary">Informații complete privind politică noastră de confidențialitate și politică Cookies găsiți  pe pagină
Politică de confidențialitate</p>
  


</div>

         </div>
         <Footer />
      </div>
    )
}

