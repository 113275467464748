import {Fragment, useCallback, useEffect, useState} from "react";
import ImageViewer from 'react-simple-image-viewer';
import gallery1 from '../assets/img/doka_1.webp'
import gallery2 from '../assets/img/doka_2.webp'
import gallery3 from '../assets/img/doka_3.webp'
import gallery4 from '../assets/img/doka_4.webp'
import gallery5 from '../assets/img/doka_5.webp'
import gallery6 from '../assets/img/doka_6.webp'
import gallery7 from '../assets/img/gallery7.webp'
import gallery8 from '../assets/img/gallery8.webp'
import img1 from '../assets/img/img1.webp'
import img2 from '../assets/img/img2.webp'
import img3 from '../assets/img/img3.webp'
import img4 from '../assets/img/img4.webp'
import img5 from '../assets/img/img5.webp'
import img6 from '../assets/img/img6.webp'
import img7 from '../assets/img/img7.webp'
import img9 from '../assets/img/img9.webp'
import Footer from "./Footer";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {FaChevronLeft, FaChevronRight} from 'react-icons/fa'
import {Rating} from '@mui/material';
import panouri from "../assets/img/panouri.webp"
import mouse_scroll from "../assets/img/mouse-scroll-3.webp"
import background_header from "../assets/img/header.webp"
import Slide from 'react-reveal/Slide';
import Fade from 'react-reveal/Fade';
import {slide as MenuBurger} from 'react-burger-menu'
import burger_menu from "../assets/img/hamburger-menu.webp"
import close from "../assets/img/close.webp"
import location from "../assets/img/location.webp"
import logo from "../assets/img/logo.webp"
import {Menu, Transition} from '@headlessui/react'

export default function Home(history) {

    useEffect(() => {
        if (window.location.href.indexOf("ofertapret") > -1) {
            document.getElementById('ofertapret').scrollIntoView({behavior: 'smooth'});
        }
        if (window.location.href.indexOf("caracteristici") > -1) {
            document.getElementById('caracteristici').scrollIntoView({behavior: 'smooth'});
        }
        if (window.location.href.indexOf("galerie") > -1) {
            document.getElementById('galerie').scrollIntoView({behavior: 'smooth'});
        }
        if (window.location.href.indexOf("testimoniale") > -1) {
            document.getElementById('testimoniale').scrollIntoView({behavior: 'smooth'});
        }
    }, [])

    const [sliderRef, setSliderRef] = useState(null)
    const [sliderRef2, setSliderRef2] = useState(null)

    const reviews = [{
        stars: 5,
        name: "Andrei Ban",
        content: "„Compania noastră recomandă Închirieri Panouri Doka Oradea, ca fiind un partener loial și de încredere. Am folosit panourile Doka la lucrările noastre și putem spune că suntem foarte multumiți de colaborare!”",
    }, {
        stars: 5,
        name: "Ioan Vinter",
        content: "„Am lucrat la un imobil cu 4 etaje și am apelat la panourile Doka pentru aceasta lucrare. Raportul preț-calitate mi s-a parut foarte bun, așa că recomand cu mare caldură Închirieri Panouri Doka Oradea.”",
    }, {
        stars: 5,
        name: "Constantin Vlad",
        content: "„Băieții de la Închirieri Panouri Doka Oradea sunt foarte profesioniști și lucrează rapid și eficient. Panourile Doka necesare pentru fiecare lucrare au fost de o calitate superioară. Voi apela tot la ei pentru fiecare proiect!”",
    }, {
        stars: 5,
        name: "Cristian Molnar",
        content: "„Am ales Închirieri Panouri Doka Oradea la recomandarea unui prieten și așa am dat de o echipă de profesioniști. Băieții sunt serioși și livrează produsele la timp. Și plus au produse net superioare. Vă mulțumesc!”",
    }]

    const products = [{
        img: img1, description: "Panouri Doka"
    }, {
        img: img2, description: "Sistem complet-cofrare planșeu"
    }, {
        img: img3, description: "Sistem complet-cofrare elevație, ziduri de sprijin, garduri"
    }, {
        img: img4, description: "Sistem, complet - cofrare stâlpi"
    }, {
        img: img5, description: "Popici metalici: 2,0-4,5m"
    }, {
        img: img6, description: "Grindă Doka"
    }, {
        img: img7, description: "Blocatori"
    },]

    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false)
    const images = [gallery7, gallery8, gallery1, gallery2, gallery3, gallery4, gallery5, gallery6];

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    const onClose = (e) => {
        setOpenMenu(false)
    }

    const onOpenMenu = (e) => {
        setOpenMenu(e.isOpen)
    }
    const [openMenu, setOpenMenu] = useState(false)

    return (<div className={`overflow-hidden`}>
        <div className={`mx-auto  relative  bg-blue-300`}>
            <img
                className="-z-10 object-cover bg-center bg-no-repeat opacity-90 brightness-[0.48] absolute w-screen h-full max-h-[35rem] xs:max-h-[32rem] sx:max-h-[31rem] sm:max-h-[37rem] md:max-h-[42rem] lg:max-h-[40rem] mid:max-h-[37rem] xl:max-h-[38rem] py-11"
                src={background_header}
                alt="people_working_with_gd-holz"
            />
            <div className="fixed top-0 w-screen z-20 border-[#F6C10D] bg-white w-full border-b-2">
                <div
                    className={`z-10 px-4 s:px-6 sm:px-8 lg:px-12 xl:px-16 max-w-7xl mx-auto hidden lg:flex w-full flex-row justify-between justify-center items-center py-6`}>
                    <img className="w-40" src={logo} alt="Logo" alt="gd-holz_logo"/>
                    <div
                        className={`flex flex-row transition-all duration-300 items-center space-x-6 lg:space-x-8 md:text-sm`}>
                            <span className="duration-300 hover:text-[#F6C10D]"><a href="#ofertapret">Solicită oferta de
                                preț</a></span>
                        <span className="duration-300 hover:text-[#F6C10D]"><a
                            href="#caracteristici">Caracteristici</a>
                            </span>
                        <span className="duration-300 hover:text-[#F6C10D]"><a
                            href="#galerie">Galerie foto</a></span>
                        <span className="duration-300 hover:text-[#F6C10D]"><a
                            href="#testimoniale">Testimoniale</a></span>

                        <div className=" top-16 w-42 text-right">
                            <Menu as="div" className="relative inline-block text-left">
                                <div className="z-0">
                                    <Menu.Button
                                        className="relative z-10 inline-flex w-full border-[#F6C10D] text-[#F6C10D] hover:transition-all duration-300 hover:text-white hover:bg-[#F6C10D] border-2 py-2.5 px-4 shadow-[0_35px_60px_-15px_rgba(246, 193, 13,0.3)] rounded-2xl justify-center items-center bg-opacity-20 px-4 py-2 text-sm font-bold">
                                        <p className="">Contactează-ne</p>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             strokeWidth={2} stroke="currentColor"
                                             className="w-4 h-4 ml-1.5">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"/>
                                        </svg>
                                    </Menu.Button>
                                </div>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items
                                        className="absolute right-0 mt-2 w-56 origin-top-right divide-y border-[#F6C10D] border-2 divide-gray-100 rounded-xl bg-white shadow-xl ocus:outline-none">
                                        <div className="px-1 py-1 ">
                                            <Menu.Item>
                                                <a href="tel:40774956974"
                                                   className="flex flex-row items-center p-2 px-4 rounded-3xl font-semibold text-primary">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                         viewBox="0 0 24 24" strokeWidth={1.5} stroke="#484744"
                                                         className="w-[1.1rem] h-[1.1rem]">
                                                        <path strokeLinecap="round" strokeLinejoin="round"
                                                              d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"/>
                                                    </svg>
                                                    <h2 className="text-sm text-[#484744] ml-2">Nr. de telefon</h2>
                                                </a>
                                            </Menu.Item>
                                            <Menu.Item>
                                                <a href="https://api.whatsapp.com/send?phone=40774956974"
                                                   className={`flex flex-row items-center p-2 px-4 rounded-3xl font-semibold text-white`}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="w-4 h-4"
                                                        fill="#25D366"
                                                        version="1.1"
                                                        viewBox="0 0 308 308"
                                                        xmlSpace="preserve"
                                                    >
                                                        <g>
                                                            <path
                                                                d="M227.904 176.981c-.6-.288-23.054-11.345-27.044-12.781-1.629-.585-3.374-1.156-5.23-1.156-3.032 0-5.579 1.511-7.563 4.479-2.243 3.334-9.033 11.271-11.131 13.642-.274.313-.648.687-.872.687-.201 0-3.676-1.431-4.728-1.888-24.087-10.463-42.37-35.624-44.877-39.867-.358-.61-.373-.887-.376-.887.088-.323.898-1.135 1.316-1.554 1.223-1.21 2.548-2.805 3.83-4.348a140.77 140.77 0 011.812-2.153c1.86-2.164 2.688-3.844 3.648-5.79l.503-1.011c2.344-4.657.342-8.587-.305-9.856-.531-1.062-10.012-23.944-11.02-26.348-2.424-5.801-5.627-8.502-10.078-8.502-.413 0 0 0-1.732.073-2.109.089-13.594 1.601-18.672 4.802C90 87.918 80.89 98.74 80.89 117.772c0 17.129 10.87 33.302 15.537 39.453.116.155.329.47.638.922 17.873 26.102 40.154 45.446 62.741 54.469 21.745 8.686 32.042 9.69 37.896 9.69h.001c2.46 0 4.429-.193 6.166-.364l1.102-.105c7.512-.666 24.02-9.22 27.775-19.655 2.958-8.219 3.738-17.199 1.77-20.458-1.348-2.216-3.671-3.331-6.612-4.743z"/>
                                                            <path
                                                                d="M156.734 0C73.318 0 5.454 67.354 5.454 150.143c0 26.777 7.166 52.988 20.741 75.928L.212 302.716a3.998 3.998 0 004.999 5.096l79.92-25.396c21.87 11.685 46.588 17.853 71.604 17.853C240.143 300.27 308 232.923 308 150.143 308 67.354 240.143 0 156.734 0zm0 268.994c-23.539 0-46.338-6.797-65.936-19.657a3.996 3.996 0 00-3.406-.467l-40.035 12.726 12.924-38.129a4.002 4.002 0 00-.561-3.647c-14.924-20.392-22.813-44.485-22.813-69.677 0-65.543 53.754-118.867 119.826-118.867 66.064 0 119.812 53.324 119.812 118.867.001 65.535-53.746 118.851-119.811 118.851z"/>
                                                        </g>
                                                    </svg>
                                                    <span className="text-[#25D366] ml-2">Whatsapp</span>
                                                </a>
                                            </Menu.Item>
                                            <Menu.Item>
                                                <a href="mailto:office@gd-holz.ro"
                                                   className="flex flex-row items-center p-2 px-4 font-semibold text-primary">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                         viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                                                         className="w-5 h-5">
                                                        <path strokeLinecap="round"
                                                              d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25"/>
                                                    </svg>
                                                    <h2 className="text-sm ml-2">Adresă de email</h2>
                                                </a>
                                            </Menu.Item>
                                        </div>
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        </div>
                    </div>
                </div>
                <div className={`flex flex-row items-center z-10 lg:hidden`}>
                    <button className="ml-2" onClick={() => {
                        setOpenMenu(true)
                    }}><img className="w-10" src={burger_menu} alt="open_menu_button"/></button>
                    <div className="-ml-12 -mt-16">
                        <MenuBurger className="" itemListElement="div" isOpen={openMenu} onStateChange={onOpenMenu}
                                    onClose={onClose} customBurgerIcon={false} customCrossIcon={false}>
                            <div className="relative bg-white h-full flex flex-col">
                                <button className="absolute right-4 top-4" onClick={() => {
                                    setOpenMenu(false)
                                }}>
                                    <img className="w-8" src={close} alt="close_menu_button"/>
                                </button>
                                <img className="mx-auto pt-12 w-40" src={logo} alt="Logo"/>
                                <div className="px-6 flex flex-col justify-start mt-6">
                                    <button className="text-left" onClick={() => {
                                        setOpenMenu(false)
                                    }}>
                                        <a id="home" href="#ofertapret" className="menu-item">Solicită oferta de
                                            preț</a>
                                    </button>
                                    <div className="w-full h-0.5 opacity-40 m-1 bg-[#F6C10D]"/>
                                    <button className="text-left" onClick={() => {
                                        setOpenMenu(false)
                                    }}>
                                        <a id="about" href="#caracteristici"
                                           className="menu-item">Caracteristici</a>
                                    </button>
                                    <div className="w-full h-0.5 m-1 opacity-40 bg-[#F6C10D]"/>
                                    <button className="text-left" onClick={() => {
                                        setOpenMenu(false)
                                    }}>
                                        <a id="contact" href="#galerie" className="menu-item">Galerie foto</a>
                                    </button>
                                    <div className="w-full h-0.5 m-1 opacity-40 bg-[#F6C10D]"/>
                                    <button className="text-left" onClick={() => {
                                        setOpenMenu(false)
                                    }}><a id="contact" href="#testimoniale" className="menu-item">Testimoniale</a>
                                    </button>
                                    <a href="tel:40774956974"
                                       className="flex flex-row items-center justify-center border-[#484744] border-2 p-2.5 px-4 rounded-3xl font-semibold text-primary mt-6">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             strokeWidth={1.5} stroke="#484744" className="w-[1.1rem] h-[1.1rem]">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"/>
                                        </svg>
                                        <h2 className="text-sm text-[#484744] ml-2">Nr. de telefon</h2>
                                    </a>
                                    <a href="https://api.whatsapp.com/send?phone=40774956974"
                                       className={`flex flex-row items-center justify-center border-[#25D366] border-2 p-2.5 px-4 rounded-3xl font-semibold text-white mt-3`}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="w-4 h-4"
                                            fill="#25D366"
                                            version="1.1"
                                            viewBox="0 0 308 308"
                                            xmlSpace="preserve"
                                        >
                                            <g>
                                                <path
                                                    d="M227.904 176.981c-.6-.288-23.054-11.345-27.044-12.781-1.629-.585-3.374-1.156-5.23-1.156-3.032 0-5.579 1.511-7.563 4.479-2.243 3.334-9.033 11.271-11.131 13.642-.274.313-.648.687-.872.687-.201 0-3.676-1.431-4.728-1.888-24.087-10.463-42.37-35.624-44.877-39.867-.358-.61-.373-.887-.376-.887.088-.323.898-1.135 1.316-1.554 1.223-1.21 2.548-2.805 3.83-4.348a140.77 140.77 0 011.812-2.153c1.86-2.164 2.688-3.844 3.648-5.79l.503-1.011c2.344-4.657.342-8.587-.305-9.856-.531-1.062-10.012-23.944-11.02-26.348-2.424-5.801-5.627-8.502-10.078-8.502-.413 0 0 0-1.732.073-2.109.089-13.594 1.601-18.672 4.802C90 87.918 80.89 98.74 80.89 117.772c0 17.129 10.87 33.302 15.537 39.453.116.155.329.47.638.922 17.873 26.102 40.154 45.446 62.741 54.469 21.745 8.686 32.042 9.69 37.896 9.69h.001c2.46 0 4.429-.193 6.166-.364l1.102-.105c7.512-.666 24.02-9.22 27.775-19.655 2.958-8.219 3.738-17.199 1.77-20.458-1.348-2.216-3.671-3.331-6.612-4.743z"/>
                                                <path
                                                    d="M156.734 0C73.318 0 5.454 67.354 5.454 150.143c0 26.777 7.166 52.988 20.741 75.928L.212 302.716a3.998 3.998 0 004.999 5.096l79.92-25.396c21.87 11.685 46.588 17.853 71.604 17.853C240.143 300.27 308 232.923 308 150.143 308 67.354 240.143 0 156.734 0zm0 268.994c-23.539 0-46.338-6.797-65.936-19.657a3.996 3.996 0 00-3.406-.467l-40.035 12.726 12.924-38.129a4.002 4.002 0 00-.561-3.647c-14.924-20.392-22.813-44.485-22.813-69.677 0-65.543 53.754-118.867 119.826-118.867 66.064 0 119.812 53.324 119.812 118.867.001 65.535-53.746 118.851-119.811 118.851z"/>
                                            </g>
                                        </svg>
                                        <span className="text-sm text-[#25D366] ml-2">Whatsapp</span>
                                    </a>
                                    <a href="mailto:office@gd-holz.ro"
                                       className="flex flex-row justify-center border-[#F6C10D] border-2 items-center rounded-3xl p-2.5 px-4 font-semibold text-primary mt-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                            <path strokeLinecap="round"
                                                  d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25"/>
                                        </svg>
                                        <h2 className="text-sm ml-2">Adresă de email</h2>
                                    </a>
                                </div>
                            </div>
                        </MenuBurger>
                    </div>
                    <img className="w-40 mx-auto py-4" src={logo} alt="Logo"/>
                </div>
            </div>
            {/*section header*/}
            <div
                className={`relative pt-16 s:pt-28 sm:pt-20 lg:pt-16 xl:pt-24 max-w-6xl w-full px-4 s:px-6 sm:px-8 lg:px-12 xl:px-16 max-w-7xl mx-auto flex flex-col sm:flex-row items-center sm:justify-between mt-4 lg:mt-11 mb-40 sm:mb-52 md:mb-36 lg:mb-20`}>
                <div className={`order-2 sm:order-1 w-full sm:w-[70%] text-white md:py-12`}>
                            <span
                                className={`flex flex-row mt-4 sm:mt-0 text-left font-extrabold text-3xl md:text-4xl`}>
                                {/*<Slide bottom>*/}
                                <h1>Închiriere panouri<span className={`text-primary ml-1.5`}>Doka</span></h1>
                                {/*</Slide>*/}
                            </span>

                    <div
                        className={`text-left font-semibold sm:font-medium text-md lg:text-md sm:text-left text-white leading-5 mt-4 text-md md:w-[85%]`}>
                        <Slide bottom>
                            Cu o experiență de peste 20 ani în domeniul construcțiilor, compania noastră oferă
                            spre
                            închireriere o gamă largă de panouri Doka și sisteme complete pentru cofrarea
                            tuturor
                            elementelor de beton.
                        </Slide>
                    </div>
                    <div
                        className={`text-left font-semibold sm:font-medium text-md lg:text-md sm:text-left text-white leading-5 mt-2 text-md md:w-[85%]`}>
                        <Slide bottom>
                            Este important pentru noi, ca fiecare client în parte să își poată finaliza
                            construcția
                            în cele mai avantajoase condiții.
                        </Slide>
                    </div>

                    <div
                        className={`mt-4 sm:mt-0 flex flex-row sm:flex-col space-x-8 sm:space-x-0 items-center justify-start sm:justify-start sm:items-start`}>
                        <div className={`sm:mt-5 flex flex-row items-center space-x-1`}>

                            <ul className={`flex flex-row  md:flex-col  text-sm font-semibold pt-2.5 pl-2.5 gap-4`}>
                                <li>
                                    <div className="flex flex-row items-center gap-3"><img className="w-9"
                                                                                           src={location}
                                                                                           alt="map_pin_icon"/>
                                        <Fade
                                            left>Oradea</Fade></div>
                                </li>
                                <li>
                                    <div className="flex flex-row items-center gap-3"><img className="w-9"
                                                                                           src={location}
                                                                                           alt="map_pin_icon"/><Fade
                                        left>Arad</Fade></div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div
                        className={`mx-auto fixed bottom-4 z-50 flex flex-row items-center justify-between w-11/12  space-x-4`}>
                        <a href="https://api.whatsapp.com/send?phone=40774956974"
                        >
                            <button
                                className="flex flex-row justify-center bg-[#f5f5f5]  items-center sm:hidden rounded-full">
                                <div className="flex flex-row items-center gap-1">
                                    <svg className="w-8"
                                         xmlns="http://www.w3.org/2000/svg"
                                         fill="#25D366"
                                         version="1.1"
                                         viewBox="0 0 308 308"
                                         xmlSpace="preserve"
                                    >
                                        <g>
                                            <path
                                                d="M227.904 176.981c-.6-.288-23.054-11.345-27.044-12.781-1.629-.585-3.374-1.156-5.23-1.156-3.032 0-5.579 1.511-7.563 4.479-2.243 3.334-9.033 11.271-11.131 13.642-.274.313-.648.687-.872.687-.201 0-3.676-1.431-4.728-1.888-24.087-10.463-42.37-35.624-44.877-39.867-.358-.61-.373-.887-.376-.887.088-.323.898-1.135 1.316-1.554 1.223-1.21 2.548-2.805 3.83-4.348a140.77 140.77 0 011.812-2.153c1.86-2.164 2.688-3.844 3.648-5.79l.503-1.011c2.344-4.657.342-8.587-.305-9.856-.531-1.062-10.012-23.944-11.02-26.348-2.424-5.801-5.627-8.502-10.078-8.502-.413 0 0 0-1.732.073-2.109.089-13.594 1.601-18.672 4.802C90 87.918 80.89 98.74 80.89 117.772c0 17.129 10.87 33.302 15.537 39.453.116.155.329.47.638.922 17.873 26.102 40.154 45.446 62.741 54.469 21.745 8.686 32.042 9.69 37.896 9.69h.001c2.46 0 4.429-.193 6.166-.364l1.102-.105c7.512-.666 24.02-9.22 27.775-19.655 2.958-8.219 3.738-17.199 1.77-20.458-1.348-2.216-3.671-3.331-6.612-4.743z"/>
                                            <path
                                                d="M156.734 0C73.318 0 5.454 67.354 5.454 150.143c0 26.777 7.166 52.988 20.741 75.928L.212 302.716a3.998 3.998 0 004.999 5.096l79.92-25.396c21.87 11.685 46.588 17.853 71.604 17.853C240.143 300.27 308 232.923 308 150.143 308 67.354 240.143 0 156.734 0zm0 268.994c-23.539 0-46.338-6.797-65.936-19.657a3.996 3.996 0 00-3.406-.467l-40.035 12.726 12.924-38.129a4.002 4.002 0 00-.561-3.647c-14.924-20.392-22.813-44.485-22.813-69.677 0-65.543 53.754-118.867 119.826-118.867 66.064 0 119.812 53.324 119.812 118.867.001 65.535-53.746 118.851-119.811 118.851z"/>
                                        </g>
                                    </svg>
                                    <p className="text-xs text-[#898989] font-medium py-2 pr-4">Bună ziua, vă putem
                                        ajuta? </p>
                                </div>
                            </button>
                        </a>
                        <a href="tel:40774956974">
                            <button
                                className="w-12 h-12 flex flex-row justify-center items-center  sm:hidden rounded-full bg-primary">
                                <svg className="w-4"
                                     xmlns="http://www.w3.org/2000/svg" version="1" viewBox="0 0 948 1280">
                                    <g fill="#fff">
                                        <path
                                            d="M3102 12793l-23-5 5-151c3-84 8-348 11-587 17-1352 53-2558 85-2790 7-52 14-70 36-89 26-21 34-23 108-19 46 3 109 15 146 28 91 31 253 114 325 165 67 47 177 159 273 278l64 78-1 267c-1 147-3 364-6 482s-10 573-16 1010l-10 795-30 61c-20 43-52 83-106 135-235 224-639 384-861 342z"
                                            transform="matrix(.1 0 0 -.1 0 1280)"
                                        ></path>
                                        <path
                                            d="M2605 12779c-291-27-655-104-914-194-860-300-1410-854-1606-1620-68-266-80-377-79-770 2-615 50-1339 134-2015 193-1539 580-2865 1091-3735 82-140 352-549 575-870C2945 1931 4002 880 5042 359 5692 32 6377-78 6915 56c369 92 802 237 1155 386 267 113 811 400 810 426-1 4-370 297-822 652s-1111 873-1464 1151l-643 505-98-48c-312-154-646-188-900-93-133 50-265 145-365 262-220 256-765 1222-1595 2823-465 897-721 1411-747 1505-121 430-71 809 144 1094 98 129 214 225 384 318l90 48-3 110c-1 61-24 868-51 1795-28 927-50 1711-50 1743v57l-32-1c-18-1-73-5-123-10z"
                                            transform="matrix(.1 0 0 -.1 0 1280)"
                                        ></path>
                                        <path
                                            d="M6603 3668c-221-117-402-216-403-219 0-8 2990-2383 3005-2387 10-3 97 255 222 658 41 134 48 167 48 241 0 81-2 90-43 172-26 53-65 111-99 148-95 102-1506 1203-1813 1414-155 106-315 170-453 181l-62 4-402-212z"
                                            transform="matrix(.1 0 0 -.1 0 1280)"
                                        ></path>
                                    </g>
                                </svg>
                            </button>
                        </a>
                    </div>
                    <div
                        className={` hidden sm:flex w-56 space-x-8 sm:space-x-0  flex-col justify-center sm:justify-start`}>

                        <a href="https://api.whatsapp.com/send?phone=40774956974"
                           className="mt-5 bg-white -ml-0.5 flex flex-row justify-center  mx-auto items-center border-[#25D366] border-2 rounded-2xl py-2">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-7 ml-3.5"
                                fill="#25D366"
                                version="1.1"
                                viewBox="0 0 308 308"
                                xmlSpace="preserve"
                            >
                                <g>
                                    <path
                                        d="M227.904 176.981c-.6-.288-23.054-11.345-27.044-12.781-1.629-.585-3.374-1.156-5.23-1.156-3.032 0-5.579 1.511-7.563 4.479-2.243 3.334-9.033 11.271-11.131 13.642-.274.313-.648.687-.872.687-.201 0-3.676-1.431-4.728-1.888-24.087-10.463-42.37-35.624-44.877-39.867-.358-.61-.373-.887-.376-.887.088-.323.898-1.135 1.316-1.554 1.223-1.21 2.548-2.805 3.83-4.348a140.77 140.77 0 011.812-2.153c1.86-2.164 2.688-3.844 3.648-5.79l.503-1.011c2.344-4.657.342-8.587-.305-9.856-.531-1.062-10.012-23.944-11.02-26.348-2.424-5.801-5.627-8.502-10.078-8.502-.413 0 0 0-1.732.073-2.109.089-13.594 1.601-18.672 4.802C90 87.918 80.89 98.74 80.89 117.772c0 17.129 10.87 33.302 15.537 39.453.116.155.329.47.638.922 17.873 26.102 40.154 45.446 62.741 54.469 21.745 8.686 32.042 9.69 37.896 9.69h.001c2.46 0 4.429-.193 6.166-.364l1.102-.105c7.512-.666 24.02-9.22 27.775-19.655 2.958-8.219 3.738-17.199 1.77-20.458-1.348-2.216-3.671-3.331-6.612-4.743z"/>
                                    <path
                                        d="M156.734 0C73.318 0 5.454 67.354 5.454 150.143c0 26.777 7.166 52.988 20.741 75.928L.212 302.716a3.998 3.998 0 004.999 5.096l79.92-25.396c21.87 11.685 46.588 17.853 71.604 17.853C240.143 300.27 308 232.923 308 150.143 308 67.354 240.143 0 156.734 0zm0 268.994c-23.539 0-46.338-6.797-65.936-19.657a3.996 3.996 0 00-3.406-.467l-40.035 12.726 12.924-38.129a4.002 4.002 0 00-.561-3.647c-14.924-20.392-22.813-44.485-22.813-69.677 0-65.543 53.754-118.867 119.826-118.867 66.064 0 119.812 53.324 119.812 118.867.001 65.535-53.746 118.851-119.811 118.851z"/>
                                </g>
                            </svg>
                            <p className="text-xs text-secondary font-medium w-32 ml-3.5">PRIMEȘTE OFERTA
                                PRIN <span className="font-extrabold">WHATSAPP</span></p>
                        </a>
                    </div>
                </div>
                <div className="inset-center -bottom-28 md:-bottom-10 xl:bottom-0 ">
                    <div className="hidden sm:block animate-bounce ">
                        <img className="w-12" alt="Scroll" src={mouse_scroll} alt="mouse_icon"/>
                    </div>
                </div>
            </div>
            {/* SCROLL */}
            {/*    section 1*/}
            <a name="ofertapret"></a>
            <div id="ofertapret"
                 className={`w-full px-4 s:px-6 sm:px-8 lg:px-12 xl:px-16 max-w-7xl mx-auto py-2 lg:py-10`}>
                <div className={`flex flex-col items-center w-full `}>
                        <span className={`text-2xl lg:text-4xl  font-bold text-center text-secondary`}>Solicită oferta de
                            preț</span>
                    <p className={`text-center text-sm max-w-[610px] text-[#575757] font-semibold mt-4`}>Închiriază
                        acum în 3 pași simpli.
                    </p>
                    <div
                        className={`flex flex-col md:flex-row items-center space-y-6 md:space-y-0 justify-between mt-16 max-w-4xl`}>
                        <div
                            className={`text-center bg-transparent  flex flex-col  items-center border border-[#DADADA] w-full md:w-[30%] h-90 px-8 pt-8 pb-12 rounded-3xl`}>
                            <div
                                className={`relative flex flex-row items-center justify-center p-4`}>
                                <svg className={`z-10 w-7 h-7`} viewBox="0 0 40 40" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11.7431 18.75C12.9831 18.75 14.1015 18.0067 14.8677 16.95C16.0619 15.2994 17.5611 13.937 19.2692 12.95C20.3815 12.31 21.3461 11.3567 21.8123 10.0917C22.1397 9.20542 22.3078 8.25958 22.3077 7.305V6.25C22.3077 5.91848 22.4292 5.60054 22.6456 5.36612C22.862 5.1317 23.1555 5 23.4615 5C24.3796 5 25.26 5.39509 25.9092 6.09835C26.5584 6.80161 26.9231 7.75544 26.9231 8.75C26.9231 10.67 26.5231 12.4883 25.8108 14.1133C25.4015 15.0433 25.9754 16.25 26.9261 16.25H31.7354C33.3138 16.25 34.7277 17.4067 34.8954 19.1083C34.9646 19.8117 35 20.525 35 21.25C35.0063 25.8106 33.5677 30.2354 30.9246 33.785C30.3277 34.5883 29.4061 35 28.4554 35H22.2769C21.5338 35 20.7938 34.87 20.0877 34.6167L15.2969 32.8833C14.5909 32.629 13.8517 32.4995 13.1077 32.5H10.6215M23.4615 16.25H26.9231M10.6215 32.5C10.7492 32.8417 10.8877 33.175 11.0369 33.5033C11.34 34.17 10.9169 35 10.2323 35H8.83539C7.4677 35 6.2 34.1367 5.80154 32.72C5.26869 30.8254 4.99856 28.8556 5.00001 26.875C5.00001 24.2867 5.45385 21.815 6.27847 19.545C6.74924 18.255 7.94923 17.5 9.23077 17.5H10.8508C11.5769 17.5 11.9969 18.4267 11.62 19.1C10.306 21.4433 9.61296 24.1332 9.61692 26.875C9.61692 28.865 9.97385 30.7633 10.6231 32.5H10.6215Z"
                                        stroke="#FFFFFF" stroke-width="3" stroke-linecap="round"
                                        stroke-linejoin="round"/>
                                </svg>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="z-0 w-16 absolute"
                                    fill="#FFFFFF"
                                    viewBox="0 0 103 100"
                                >
                                    <path
                                        fill="#F6C10D"
                                        fillRule="evenodd"
                                        d="M51.836 4.341c9.566 1.964 17.874 6.265 26.14 11.37 8.913 5.507 19.88 9.75 23.649 19.386 3.82 9.769-1.219 20.291-4.204 30.336C94.33 75.838 92.807 87.708 84.03 94.31c-8.85 6.657-21.04 5.84-32.193 5.41-10.733-.415-21.51-1.927-30.4-7.827C12.35 85.86 5.592 76.943 2.144 66.739c-3.461-10.243-2.219-21.03.124-31.572C4.859 23.504 6.005 9.449 16.297 3.049 26.554-3.33 39.943 1.899 51.836 4.34z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </div>
                            <span className={`text-lg  md:text-xl font-bold mt-2 `}>Cere oferta</span>
                            <h2 className={`  text-left  mt-2 leading-normal`}>

                                <div className={`flex flex-col text-center text-base `}>
                                    <div className="flex flex-row items-center gap-1">

                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             stroke-width="1.5" stroke="#898989" className="w-5 h-5">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                  d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"/>
                                        </svg>


                                        <span className="text-light-gray">Formular de contact</span></div>
                                    <div className="flex flex-row items-center gap-1">
                                        <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M3 6.5C3 14.5081 9.49187 21 17.5 21C18.166 21 18.8216 20.9551 19.4637 20.8682C20.3747 20.7448 21 19.9292 21 19.01V16.4415C21 15.5807 20.4491 14.8164 19.6325 14.5442L16.4841 13.4947C15.6836 13.2279 14.8252 13.699 14.6206 14.5177C14.3475 15.6102 12.987 15.987 12.1907 15.1907L8.80926 11.8093C8.01301 11.013 8.38984 9.65254 9.48229 9.37943C10.301 9.17476 10.7721 8.31644 10.5053 7.51586L9.45585 4.36754C9.18362 3.55086 8.41934 3 7.55848 3H4.99004C4.0708 3 3.25518 3.62533 3.13185 4.53627C3.0449 5.17845 3 5.83398 3 6.5Z"
                                                stroke="#323232" stroke-width="2" stroke-linejoin="round"/>
                                        </svg>
                                        <span>
                                                <a href="tel:+40774956974"
                                                   className=" text-[#484744] ">0774956974</a></span>
                                    </div>
                                    <div className="flex flex-row items-center gap-1">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="w-5 h-5"
                                            fill="#25D366"
                                            version="1.1"
                                            viewBox="0 0 308 308"
                                            xmlSpace="preserve"
                                        >
                                            <g>
                                                <path
                                                    d="M227.904 176.981c-.6-.288-23.054-11.345-27.044-12.781-1.629-.585-3.374-1.156-5.23-1.156-3.032 0-5.579 1.511-7.563 4.479-2.243 3.334-9.033 11.271-11.131 13.642-.274.313-.648.687-.872.687-.201 0-3.676-1.431-4.728-1.888-24.087-10.463-42.37-35.624-44.877-39.867-.358-.61-.373-.887-.376-.887.088-.323.898-1.135 1.316-1.554 1.223-1.21 2.548-2.805 3.83-4.348a140.77 140.77 0 011.812-2.153c1.86-2.164 2.688-3.844 3.648-5.79l.503-1.011c2.344-4.657.342-8.587-.305-9.856-.531-1.062-10.012-23.944-11.02-26.348-2.424-5.801-5.627-8.502-10.078-8.502-.413 0 0 0-1.732.073-2.109.089-13.594 1.601-18.672 4.802C90 87.918 80.89 98.74 80.89 117.772c0 17.129 10.87 33.302 15.537 39.453.116.155.329.47.638.922 17.873 26.102 40.154 45.446 62.741 54.469 21.745 8.686 32.042 9.69 37.896 9.69h.001c2.46 0 4.429-.193 6.166-.364l1.102-.105c7.512-.666 24.02-9.22 27.775-19.655 2.958-8.219 3.738-17.199 1.77-20.458-1.348-2.216-3.671-3.331-6.612-4.743z"/>
                                                <path
                                                    d="M156.734 0C73.318 0 5.454 67.354 5.454 150.143c0 26.777 7.166 52.988 20.741 75.928L.212 302.716a3.998 3.998 0 004.999 5.096l79.92-25.396c21.87 11.685 46.588 17.853 71.604 17.853C240.143 300.27 308 232.923 308 150.143 308 67.354 240.143 0 156.734 0zm0 268.994c-23.539 0-46.338-6.797-65.936-19.657a3.996 3.996 0 00-3.406-.467l-40.035 12.726 12.924-38.129a4.002 4.002 0 00-.561-3.647c-14.924-20.392-22.813-44.485-22.813-69.677 0-65.543 53.754-118.867 119.826-118.867 66.064 0 119.812 53.324 119.812 118.867.001 65.535-53.746 118.851-119.811 118.851z"/>
                                            </g>
                                        </svg>
                                        <span><a href="https://api.whatsapp.com/send?phone=40774956974"
                                                 className=" text-[#25D366]  ">Whatsapp</a>
                                        </span></div>
                                    <div className="flex flex-row items-center gap-1">

                                        <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M4 7.00005L10.2 11.65C11.2667 12.45 12.7333 12.45 13.8 11.65L20 7"
                                                stroke="#F6C10D" stroke-width="2" stroke-linecap="round"
                                                stroke-linejoin="round"/>
                                            <rect x="3" y="5" width="18" height="14" rx="2" stroke="#F6C10D"
                                                  stroke-width="2" stroke-linecap="round"/>
                                        </svg>
                                        <span><a href="mailto:office@gd-holz.ro"
                                                 className=" text-[#F6C10D] ">office@gd-holz.ro</a></span>
                                    </div>
                                </div>
                            </h2>
                        </div>
                        <div
                            className={` text-center bg-transparent  flex flex-col  items-center border border-[#DADADA] w-full md:w-[30%] h-90 px-8 pt-8 pb-12 rounded-3xl`}>
                            <div
                                className={`relative flex flex-row items-center justify-center p-4`}>
                                <svg className={`z-10 text-white w-7 h-7`} viewBox="0 0 40 40" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M23.75 12.9267C22.6189 12.5279 21.4088 12.4062 20.221 12.5718C19.0332 12.7374 17.9024 13.1854 16.9235 13.8782C15.9446 14.5711 15.1461 15.4886 14.5951 16.5538C14.044 17.6189 13.7564 18.8007 13.7564 20C13.7564 21.1993 14.044 22.3811 14.5951 23.4462C15.1461 24.5114 15.9446 25.4289 16.9235 26.1218C17.9024 26.8146 19.0332 27.2626 20.221 27.4282C21.4088 27.5938 22.6189 27.4721 23.75 27.0733M12.5 17.5H21.25M12.5 22.5H21.25M35 20C35 21.9698 34.612 23.9204 33.8582 25.7403C33.1044 27.5601 31.9995 29.2137 30.6066 30.6066C29.2137 31.9995 27.5601 33.1044 25.7403 33.8582C23.9204 34.612 21.9698 35 20 35C18.0302 35 16.0796 34.612 14.2597 33.8582C12.4399 33.1044 10.7863 31.9995 9.3934 30.6066C8.00052 29.2137 6.89563 27.5601 6.14181 25.7403C5.38799 23.9204 5 21.9698 5 20C5 16.0218 6.58035 12.2064 9.3934 9.3934C12.2064 6.58035 16.0218 5 20 5C23.9782 5 27.7936 6.58035 30.6066 9.3934C33.4196 12.2064 35 16.0218 35 20Z"
                                        stroke="#FFFFFF" stroke-width="3" stroke-linecap="round"
                                        stroke-linejoin="round"/>
                                </svg>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="absolute z-0 w-16"
                                    fill="none"
                                    viewBox="0 0 109 106"
                                >
                                    <path
                                        fill="#F6C10D"
                                        fillRule="evenodd"
                                        d="M11.484 40.858c4.373-8.731 10.678-15.643 17.75-22.306C36.857 11.367 43.795 1.872 54.077.726c10.425-1.162 19.285 6.43 28.215 11.912 9.25 5.68 20.322 10.222 24.427 20.41 4.14 10.271.195 21.835-3.107 32.496-3.178 10.26-7.428 20.278-15.428 27.338-8.178 7.217-18.542 11.435-29.29 12.125-10.79.692-20.889-3.3-30.465-8.291C17.836 91.194 4.556 86.449 1.038 74.852c-3.507-11.559 5.01-23.138 10.446-33.994z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </div>
                            <span className={`text-lg md:text-xl lg:text-xl font-bold mt-2`}>Contract</span>
                            <h2 className={`text-base text-center text-light-gray mt-2 leading-normal`}>Semnăm
                                contractul și ne pregătim pentru livrarea produselor.</h2>
                        </div>
                        <div
                            className={` text-center bg-transparent flex flex-col items-center border border-[#DADADA] w-full md:w-[30%] h-90 px-8 pt-8 pb-8 rounded-3xl`}>
                            <div
                                className={`relative flex flex-row items-center justify-center p-4`}>
                                <svg className={`z-10 w-7 h-7`} viewBox="0 0 40 40" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M14.2464 29.6001C14.2464 30.2366 14.0028 30.847 13.5693 31.2971C13.1358 31.7472 12.5479 32 11.9348 32C11.3217 32 10.7338 31.7472 10.3003 31.2971C9.86675 30.847 9.62321 30.2366 9.62321 29.6001M14.2464 29.6001C14.2464 28.9636 14.0028 28.3531 13.5693 27.9031C13.1358 27.453 12.5479 27.2002 11.9348 27.2002C11.3217 27.2002 10.7338 27.453 10.3003 27.9031C9.86675 28.3531 9.62321 28.9636 9.62321 29.6001M14.2464 29.6001H23.4927M9.62321 29.6001H6.73372C6.27392 29.6001 5.83295 29.4104 5.50782 29.0729C5.18269 28.7353 5.00003 28.2775 5.00003 27.8001V22.4003M31.5833 29.6001C31.5833 30.2366 31.3398 30.847 30.9063 31.2971C30.4728 31.7472 29.8848 32 29.2717 32C28.6586 32 28.0707 31.7472 27.6372 31.2971C27.2037 30.847 26.9601 30.2366 26.9601 29.6001M31.5833 29.6001C31.5833 28.9636 31.3398 28.3531 30.9063 27.9031C30.4728 27.453 29.8848 27.2002 29.2717 27.2002C28.6586 27.2002 28.0707 27.453 27.6372 27.9031C27.2037 28.3531 26.9601 28.9636 26.9601 29.6001M31.5833 29.6001H33.317C34.274 29.6001 35.0569 28.7937 34.9968 27.8017C34.6871 22.5181 32.9732 17.427 30.0453 13.0934C29.7665 12.6875 29.4024 12.3527 28.9805 12.1143C28.5587 11.8759 28.0902 11.7402 27.6105 11.7174H23.4927M26.9601 29.6001H23.4927M23.4927 11.7174V10.1847C23.4927 9.27593 22.8424 8.50795 21.9717 8.41515C16.8353 7.86162 11.6575 7.86162 6.52105 8.41515C6.10151 8.46229 5.71372 8.66933 5.43274 8.99622C5.15176 9.3231 4.99758 9.74658 5.00003 10.1847V22.4003M23.4927 11.7174V22.4003M23.4927 29.6001V22.4003M23.4927 22.4003H5.00003"
                                        stroke="#FFFFFF" stroke-width="3" stroke-linecap="round"
                                        stroke-linejoin="round"/>
                                </svg>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="absolute z-0 w-16"
                                    fill="none"
                                    viewBox="0 0 109 96"
                                >
                                    <path
                                        fill="#F6C10D"
                                        fillRule="evenodd"
                                        d="M75.039 91.71c-9.266 3.082-18.612 3.511-28.324 3.223-10.472-.312-22.091 1.496-30.172-4.964-8.193-6.55-9.09-18.182-11.528-28.373C2.49 51.038-2.127 39.998 2.174 29.89 6.51 19.7 17.476 14.313 27.35 9.109 36.852 4.102 46.94.023 57.59.688c10.886.68 21.196 5.025 29.284 12.138 8.119 7.14 12.437 17.103 15.679 27.404 3.587 11.396 9.622 24.14 3.91 34.83-5.694 10.652-19.903 12.817-31.424 16.65z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </div>
                            <span className={`text-lg md:text-lg lg:text-xl font-bold mt-2`}>Livrare</span>
                            <h2 className={`text-base text-center text-light-gray mt-1 leading-normal`}>Îți
                                livrăm
                                produsele oriunde dorești, pe toată raza județului:
                            </h2>
                            <div className={`flex flex-row gap-6 mt-4 font-bold`}>
                                <div className="flex flex-row items-center gap-2 ">
                                    <svg className="w-5 h-5" viewBox="0 0 42 42" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M26.25 18.375C26.25 19.7674 25.6969 21.1027 24.7123 22.0873C23.7277 23.0719 22.3924 23.625 21 23.625C19.6076 23.625 18.2723 23.0719 17.2877 22.0873C16.3031 21.1027 15.75 19.7674 15.75 18.375C15.75 16.9826 16.3031 15.6473 17.2877 14.6627C18.2723 13.6781 19.6076 13.125 21 13.125C22.3924 13.125 23.7277 13.6781 24.7123 14.6627C25.6969 15.6473 26.25 16.9826 26.25 18.375V18.375Z"
                                            stroke="#F6C10D" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round"/>
                                        <path
                                            d="M34.125 18.375C34.125 30.8735 21 38.0625 21 38.0625C21 38.0625 7.875 30.8735 7.875 18.375C7.875 14.894 9.25781 11.5556 11.7192 9.09422C14.1806 6.63281 17.519 5.25 21 5.25C24.481 5.25 27.8194 6.63281 30.2808 9.09422C32.7422 11.5556 34.125 14.894 34.125 18.375V18.375Z"
                                            stroke="#F6C10D" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round"/>
                                    </svg>
                                    <span
                                        className="text-base text-center text-light-gray">Bihor</span>
                                </div>
                                <div className="flex flex-row items-center gap-2">
                                    <svg className="w-5 h-5" viewBox="0 0 42 42" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M26.25 18.375C26.25 19.7674 25.6969 21.1027 24.7123 22.0873C23.7277 23.0719 22.3924 23.625 21 23.625C19.6076 23.625 18.2723 23.0719 17.2877 22.0873C16.3031 21.1027 15.75 19.7674 15.75 18.375C15.75 16.9826 16.3031 15.6473 17.2877 14.6627C18.2723 13.6781 19.6076 13.125 21 13.125C22.3924 13.125 23.7277 13.6781 24.7123 14.6627C25.6969 15.6473 26.25 16.9826 26.25 18.375V18.375Z"
                                            stroke="#F6C10D" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round"/>
                                        <path
                                            d="M34.125 18.375C34.125 30.8735 21 38.0625 21 38.0625C21 38.0625 7.875 30.8735 7.875 18.375C7.875 14.894 9.25781 11.5556 11.7192 9.09422C14.1806 6.63281 17.519 5.25 21 5.25C24.481 5.25 27.8194 6.63281 30.2808 9.09422C32.7422 11.5556 34.125 14.894 34.125 18.375V18.375Z"
                                            stroke="#F6C10D" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round"/>
                                    </svg>
                                    <span
                                        className="text-base text-center text-light-gray ">Arad</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*    numbers*/}
            <div className={`w-full px-4 s:px-6 sm:px-8 sm:py-12 lg:px-12 xl:px-16 max-w-7xl mx-auto `}>
                <div className={`border-[#F6C10D] border-2 py-10 sm:py-10 my-8 rounded-[60px] `}>
                    <div
                        className={`flex flex-col space-y-10 sm:space-y-0 sm:flex-row mx-auto justify-between w-4/6 lg:w-3/5`}>
                        <div className={`flex flex-col items-center space-y-2`}>
                            <h2 className={`text-primary font-bold text-4xl`}>800+</h2>
                            <h3 className={`text-secondary font-bold text-sm`}>Clienți multumiți</h3>
                        </div>
                        <div className="mx-auto h-0.5 sm:h-20 sm:w-0.5 w-20 bg-[#F6C10D]"/>
                        <div className={`flex flex-col items-center space-y-2`}>
                            <h2 className={`text-primary font-bold text-4xl`}>500+</h2>
                            <h3 className={`text-secondary font-bold text-sm`}>Proiecte</h3>
                        </div>
                    </div>
                </div>
            </div>

            {/*    about*/}
            <a name="caracteristici"></a>
            <div id="caracteristici"
                 className="flex flex-col md:flex-row justify-center mt-6  px-4 s:px-6 sm:px-8 sm:py-12 lg:px-12 xl:px-16 max-w-7xl mx-auto  ">
                <div className="flex flex-col items-center  md:w-1/2">
                    <h2 className="text-lg font-bold text-gray-900  text-lg md:text-xl lg:text-2xl text-center  mt-8 md:mt-0 ">Avantajele
                        cofrării cu panouri Doka</h2>
                    <img src={panouri}
                         className="justify-center w-56 md:w-40  lg:w-50 object-cover"
                         alt="gd-holz_panels"
                    />
                    <div className="flex flex-row items-start">
                        <svg className="w-[5%] " xmlns="http://www.w3.org/2000/svg"

                             fill="#31c966"
                             stroke="#31c966"
                             strokeWidth="0"
                             version="1.1"
                             viewBox="-0.47 -0.47 47.94 47.94"
                             xmlSpace="preserve"
                        >
                            <path
                                d="M37.6 0H9.4A9.4 9.4 0 000 9.4v28.2A9.4 9.4 0 009.4 47h28.2a9.4 9.4 0 009.4-9.4V9.4A9.4 9.4 0 0037.6 0zm.543 19.139L23.602 33.678a4.088 4.088 0 01-2.906 1.205c-1.051 0-2.104-.4-2.907-1.205l-8.933-8.932a4.113 4.113 0 015.816-5.814l6.023 6.023 11.633-11.633a4.112 4.112 0 015.815 5.817z"></path>
                        </svg>
                        <span className="pl-1 w-full  text-base md:text-lg ">
                                                                   Durabilitate crescută: panourile Doka sunt realizate din materiale de calitate și sunt proiectate pentru a rezista la utilizarea îndelungată și la condițiile dure din șantiere.</span>
                    </div>
                    <div className="flex flex-row items-start">
                        <svg className="w-[5%] " xmlns="http://www.w3.org/2000/svg"
                             fill="#31c966"
                             stroke="#31c966"
                             strokeWidth="0"
                             version="1.1"
                             viewBox="-0.47 -0.47 47.94 47.94"
                             xmlSpace="preserve"
                        >
                            <path
                                d="M37.6 0H9.4A9.4 9.4 0 000 9.4v28.2A9.4 9.4 0 009.4 47h28.2a9.4 9.4 0 009.4-9.4V9.4A9.4 9.4 0 0037.6 0zm.543 19.139L23.602 33.678a4.088 4.088 0 01-2.906 1.205c-1.051 0-2.104-.4-2.907-1.205l-8.933-8.932a4.113 4.113 0 015.816-5.814l6.023 6.023 11.633-11.633a4.112 4.112 0 015.815 5.817z"></path>
                        </svg>
                        <span className="pl-1 w-full  text-base md:text-lg ">
                                                                   Precizie și uniformitate mai bună a betonului: panourile Doka sunt fabricate cu precizie și permit o formare mai precisă și uniformă a betonului, ceea ce duce la o calitate superioară a construcției.
                                                                </span>
                    </div>

                    <div className="flex flex-row items-start">
                        <svg className="w-[5%] " xmlns="http://www.w3.org/2000/svg"

                             fill="#31c966"
                             stroke="#31c966"
                             strokeWidth="0"
                             version="1.1"
                             viewBox="-0.47 -0.47 47.94 47.94"
                             xmlSpace="preserve"
                        >
                            <path
                                d="M37.6 0H9.4A9.4 9.4 0 000 9.4v28.2A9.4 9.4 0 009.4 47h28.2a9.4 9.4 0 009.4-9.4V9.4A9.4 9.4 0 0037.6 0zm.543 19.139L23.602 33.678a4.088 4.088 0 01-2.906 1.205c-1.051 0-2.104-.4-2.907-1.205l-8.933-8.932a4.113 4.113 0 015.816-5.814l6.023 6.023 11.633-11.633a4.112 4.112 0 015.815 5.817z"></path>
                        </svg>
                        <span className="pl-1 w-full  text-base md:text-lg ">
                                                                   Siguranță și eficiență în utilizare: panourile Doka sunt proiectate pentru a fi ușor de manevrat și de asamblat, ceea ce duce la o siguranță și o eficiență crescută în utilizare.
                        </span>
                    </div>
                    <div className="flex flex-row items-start">
                        <svg className="w-[5%] " xmlns="http://www.w3.org/2000/svg"

                             fill="#31c966"
                             stroke="#31c966"
                             strokeWidth="0"
                             version="1.1"
                             viewBox="-0.47 -0.47 47.94 47.94"
                             xmlSpace="preserve"
                        >
                            <path
                                d="M37.6 0H9.4A9.4 9.4 0 000 9.4v28.2A9.4 9.4 0 009.4 47h28.2a9.4 9.4 0 009.4-9.4V9.4A9.4 9.4 0 0037.6 0zm.543 19.139L23.602 33.678a4.088 4.088 0 01-2.906 1.205c-1.051 0-2.104-.4-2.907-1.205l-8.933-8.932a4.113 4.113 0 015.816-5.814l6.023 6.023 11.633-11.633a4.112 4.112 0 015.815 5.817z"></path>
                        </svg>
                        <span className="pl-1 w-full  text-base md:text-lg ">
                                                                   Posibilitatea de a fi utilizate în medii cu condiții dure sau cerințe de incendiu: panourile Doka pot fi utilizate în medii cu conditți dure sau cu cerințe de incendiu, cum ar fi în construcțiile cu înălțime mare sau în medii industriale.
                                                                </span>
                    </div>
                    <div className="flex flex-row items-start">
                        <svg className="w-[5%] " xmlns="http://www.w3.org/2000/svg"

                             fill="#31c966"
                             stroke="#31c966"
                             strokeWidth="0"
                             version="1.1"
                             viewBox="-0.47 -0.47 47.94 47.94"
                             xmlSpace="preserve"
                        >
                            <path
                                d="M37.6 0H9.4A9.4 9.4 0 000 9.4v28.2A9.4 9.4 0 009.4 47h28.2a9.4 9.4 0 009.4-9.4V9.4A9.4 9.4 0 0037.6 0zm.543 19.139L23.602 33.678a4.088 4.088 0 01-2.906 1.205c-1.051 0-2.104-.4-2.907-1.205l-8.933-8.932a4.113 4.113 0 015.816-5.814l6.023 6.023 11.633-11.633a4.112 4.112 0 015.815 5.817z"></path>
                        </svg>
                        <span className="pl-1 w-full  text-base md:text-lg ">
                                                                    Rezistență și durabilitate: Panourile Doka sunt proiectate  să fie mai rezistente decat lemnul și să reziste la uzură și deteriorare în timp.
                                                                    Aceasta înseamnă că panourile pot fi reutilizate de mai multe ori, reducând costurile de înlocuire și de gestionare a deșeurilor.
                                                                </span>
                    </div>
                    <div className="flex flex-row items-start">
                        <svg className="w-[5%] " xmlns="http://www.w3.org/2000/svg"
                             fill="#31c966"
                             stroke="#31c966"
                             strokeWidth="0"
                             version="1.1"
                             viewBox="-0.47 -0.47 47.94 47.94"
                             xmlSpace="preserve"
                        >
                            <path
                                d="M37.6 0H9.4A9.4 9.4 0 000 9.4v28.2A9.4 9.4 0 009.4 47h28.2a9.4 9.4 0 009.4-9.4V9.4A9.4 9.4 0 0037.6 0zm.543 19.139L23.602 33.678a4.088 4.088 0 01-2.906 1.205c-1.051 0-2.104-.4-2.907-1.205l-8.933-8.932a4.113 4.113 0 015.816-5.814l6.023 6.023 11.633-11.633a4.112 4.112 0 015.815 5.817z"></path>
                        </svg>

                        <span className="pl-1 w-full text-base md:text-lg ">
                                                                Eficență și economie de timp: Panourile Doka sunt ușor de ansamblat  și demontat, ceea ce reduce timpul necesar pentru cofrarea betonului, instalarea și demontarea sistemului de cofrare. Acest lucru poate ajuta la economisirea timpului și banilor.
                                                             </span>
                    </div>
                    <div className="flex flex-row items-start">
                        <svg className="w-[5%] " xmlns="http://www.w3.org/2000/svg"
                             fill="#31c966"
                             stroke="#31c966"
                             strokeWidth="0"
                             version="1.1"
                             viewBox="-0.47 -0.47 47.94 47.94"
                             xmlSpace="preserve"
                        >
                            <path
                                d="M37.6 0H9.4A9.4 9.4 0 000 9.4v28.2A9.4 9.4 0 009.4 47h28.2a9.4 9.4 0 009.4-9.4V9.4A9.4 9.4 0 0037.6 0zm.543 19.139L23.602 33.678a4.088 4.088 0 01-2.906 1.205c-1.051 0-2.104-.4-2.907-1.205l-8.933-8.932a4.113 4.113 0 015.816-5.814l6.023 6.023 11.633-11.633a4.112 4.112 0 015.815 5.817z"></path>
                        </svg>

                        <span className="pl-1 w-full text-base md:text-lg ">
                                                                Calitatea betonului: Cofrarea  cu panouri Doka poate ajuta la îmbunătațirea calității betonului prin asigurarea unei suprafețe netede și uniforme și prin eliminarea golurilor sau a altor imperfecțiuni care ar putea apprea în timpul procesului de turnare
                                                             </span>
                    </div>
                </div>
                <div className="flex flex-col items-center md:w-1/2  ">
                    <h2 className="  font-bold text-gray-900   text-lg md:text-xl lg:text-2xl text-center  mt-8 md:mt-0">Dezavantajele
                        cofrari cu lemn</h2>

                    <img src={img9}
                         className="justify-center w-56 md:w-40  lg:w-50 object-cover mt-6"
                         alt="wood_planks"
                    />
                    <div className="flex flex-row w-full items-start  mt-2">
                        <svg className="w-[5%] " xmlns="http://www.w3.org/2000/svg"

                             fill="#ff0026"
                             stroke="#ff0026"
                             transform="rotate(180)"
                             viewBox="0 0 1024 1024"
                        >
                            <path
                                fill="#fa0000"
                                d="M195.2 195.2a64 64 0 0190.496 0L512 421.504 738.304 195.2a64 64 0 0190.496 90.496L602.496 512 828.8 738.304a64 64 0 01-90.496 90.496L512 602.496 285.696 828.8a64 64 0 01-90.496-90.496L421.504 512 195.2 285.696a64 64 0 010-90.496z"
                            ></path>
                        </svg>

                        <span className="pl-1 w-full text-base  md:text-lg ">
                                                                Rezistența și întreținere scăzută: cofrajele din lemn sunt mai puțin
                                                            rezistente și mai dificil de întreținut decât panourile Doka.
                                                                </span>
                    </div>
                    <div className="flex flex-row w-full items-start">
                        <svg className="w-[5%] " xmlns="http://www.w3.org/2000/svg"
                             fill="#ff0026"
                             stroke="#ff0026"
                             transform="rotate(180)"
                             viewBox="0 0 1024 1024"
                        >
                            <path
                                fill="#fa0000"
                                d="M195.2 195.2a64 64 0 0190.496 0L512 421.504 738.304 195.2a64 64 0 0190.496 90.496L602.496 512 828.8 738.304a64 64 0 01-90.496 90.496L512 602.496 285.696 828.8a64 64 0 01-90.496-90.496L421.504 512 195.2 285.696a64 64 0 010-90.496z"
                            ></path>
                        </svg>
                        <span className="pl-2.5 w-full  text-base md:text-lg ">
                                                                Precizie și uniformitate scăzută a betonului: cofrajele din lemn pot fi mai
                                                                                puțin precise și uniforme în formarea betonului, ceea ce poate duce la o
                                                                                calitate scăzută a construcției.
                                                                </span>
                    </div>
                    <div className="flex flex-row items-start">
                        <svg className="w-[5%] " xmlns="http://www.w3.org/2000/svg"
                             fill="#ff0026"
                             stroke="#ff0026"
                             transform="rotate(180)"
                             viewBox="0 0 1024 1024"
                        >
                            <path
                                fill="#fa0000"
                                d="M195.2 195.2a64 64 0 0190.496 0L512 421.504 738.304 195.2a64 64 0 0190.496 90.496L602.496 512 828.8 738.304a64 64 0 01-90.496 90.496L512 602.496 285.696 828.8a64 64 0 01-90.496-90.496L421.504 512 195.2 285.696a64 64 0 010-90.496z"
                            ></path>
                        </svg>
                        <span className="pl-1 w-full text-base md:text-lg">
                                                                Siguranța și eficiența scăzută în utilizare: cofrajele din lemn pot fi mai puțin sigure și eficiente în utilizare decât panourile Doka.
                                                                </span>
                    </div>
                    <div className="flex flex-row items-start">
                        <svg className="w-[5%] " xmlns="http://www.w3.org/2000/svg"
                             fill="#ff0026"
                             stroke="#ff0026"
                             transform="rotate(180)"
                             viewBox="0 0 1024 1024"
                        >
                            <path
                                fill="#fa0000"
                                d="M195.2 195.2a64 64 0 0190.496 0L512 421.504 738.304 195.2a64 64 0 0190.496 90.496L602.496 512 828.8 738.304a64 64 0 01-90.496 90.496L512 602.496 285.696 828.8a64 64 0 01-90.496-90.496L421.504 512 195.2 285.696a64 64 0 010-90.496z"
                            ></path>
                        </svg>
                        <span className="pl-1 w-full text-base md:text-lg ">
                                                                    Posibilitate redusă de a fi utilizate în medii cu condiții dure sau cerințe
                                            de incendiu: cofrajele din lemn pot fi mai puțin potrivite pentru medii cu
                                            condiții dure sau cerințe de incendiu decât panourile Doka.
                                                                </span>
                    </div>
                    <div className="flex flex-row items-start">
                        <svg className="w-[5%] " xmlns="http://www.w3.org/2000/svg"
                             fill="#ff0026"
                             stroke="#ff0026"
                             transform="rotate(180)"
                             viewBox="0 0 1024 1024"
                        >
                            <path
                                fill="#fa0000"
                                d="M195.2 195.2a64 64 0 0190.496 0L512 421.504 738.304 195.2a64 64 0 0190.496 90.496L602.496 512 828.8 738.304a64 64 0 01-90.496 90.496L512 602.496 285.696 828.8a64 64 0 01-90.496-90.496L421.504 512 195.2 285.696a64 64 0 010-90.496z"
                            ></path>
                        </svg>

                        <span className="pl-1 w-full text-base md:text-lg ">
                                                                   Durata de viață limitată: Lemnul poate fi deteriorat în timp și trebuie înlocuit regulat. Aceasta poate fi costisitoare și poate duce la producerea deșeurilor.
                                                                </span>
                    </div>
                    <div className="flex flex-row items-start">
                        <svg className="w-[5%] " xmlns="http://www.w3.org/2000/svg"
                             fill="#ff0026"
                             stroke="#ff0026"
                             transform="rotate(180)"
                             viewBox="0 0 1024 1024"
                        >
                            <path
                                fill="#fa0000"
                                d="M195.2 195.2a64 64 0 0190.496 0L512 421.504 738.304 195.2a64 64 0 0190.496 90.496L602.496 512 828.8 738.304a64 64 0 01-90.496 90.496L512 602.496 285.696 828.8a64 64 0 01-90.496-90.496L421.504 512 195.2 285.696a64 64 0 010-90.496z"
                            ></path>
                        </svg>
                        <span className="pl-2 w-full text-base md:text-lg ">
                                                               Instabilitate și dimensiuni inexacte: Din cauza variațiilor naturale în dimensiune și formă, lemnul poate fi mai dificil de ajustat și poate necesita mai mult timp și efort pentru a obține un cofraj precis și stabil
                                                                </span>
                    </div>
                    <div className="flex flex-row items-start">
                        <svg className="w-[5%] " xmlns="http://www.w3.org/2000/svg"
                             fill="#ff0026"
                             stroke="#ff0026"
                             transform="rotate(180)"
                             viewBox="0 0 1024 1024"
                        >
                            <path
                                fill="#fa0000"
                                d="M195.2 195.2a64 64 0 0190.496 0L512 421.504 738.304 195.2a64 64 0 0190.496 90.496L602.496 512 828.8 738.304a64 64 0 01-90.496 90.496L512 602.496 285.696 828.8a64 64 0 01-90.496-90.496L421.504 512 195.2 285.696a64 64 0 010-90.496z"
                            ></path>
                        </svg>
                        <span className="pl-2 w-full text-base md:text-lg ">
                                                                  Risc de incendiu: Lemnul este inflamabil și poate reprezenta un risc de incendiu la locul de munca.
                                                                </span>
                    </div>
                </div>
            </div>
            <h2 className="font-bold text-base md:text-lg  lg:text-2xl text-center  px-4 s:px-6 sm:px-8 sm:py-12 lg:px-12 xl:px-16 max-w-7xl mx-auto">În
                concluzie, cofrarea cu panouri Doka
                poate fi mai avantajoasa decat cofrarea cu lemn in termeni de rezistență, durabilitate, eficență și
                calitate a betonului.</h2>

            {/* Carousel */}
            <div className={`mt-16 w-full px-4 s:px-6 sm:px-8 lg:px-12 xl:px-16 max-w-7xl mx-auto py-4 lg:py-16`}>
                <h2 className={`text-center text-secondary font-bold text-4xl `}>Echipamente de închiriat</h2>
                <div className=" mx-auto  mt-12 sm:mt-12 lg:mt-20">
                    <Slider ref={setSliderRef2}
                            centerMode={true}
                            infinite={false}
                            centerPadding={'60px'}
                            slidesToShow={1}
                            speed={500}
                            variableWidth={true}
                    >
                        {products?.map((item, i) => (<div key={i}
                                                          className={`  sm:mt-0 relative productContainer  rounded-2xl shadow-[20px_35px_45px_-60px_rgba(5,5,70,0.6)] py-4 px-2 sm:px-10 border-2 border-primary `}>
                            <div
                                className={`flex flex-col sm:flex-row justify-center items-center gap-4 sm:gap-12 text-center`}>
                                <h2 className={`font-bold text-sm `}>{item.description}</h2>
                                <img className={`w-28 sm:w-36 sm:h-36`} src={item.img}
                                     alt="product_image"/>
                            </div>
                        </div>))}
                    </Slider>
                </div>
                <div className='mt-8 flex flex-row justify-center space-x-4'>
                    <button
                        className={`text-light-gray active:text-white rounded-full active:bg-[#000] w-10 h-10 flex flex-row items-center justify-center`}
                        onClick={sliderRef2?.slickPrev}>
                        <FaChevronLeft className={`-ml-1`}/>
                    </button>
                    <button
                        className={`text-light-gray active:text-white rounded-full active:bg-[#000] w-10 h-10 flex flex-row items-center justify-center`}
                        onClick={sliderRef2?.slickNext}>
                        <FaChevronRight className={`-mr-1`}/>
                    </button>
                </div>
            </div>

            {/*    gallery*/}
            <div id="galerie"
                 className={`mt-16 w-full px-4 s:px-6 sm:px-8 lg:px-12 xl:px-16 max-w-7xl mx-auto py-4 lg:py-16`}>
                <h2 className={`text-center text-secondary font-bold  text-4xl`}>Galerie foto</h2>
                <picture className={`grid sm:grid-cols-2 gap-8 mt-12 lg:px-16`}>
                    {images.map((src, index) => (<img
                        className={`w-full h-80 rounded-[1.8rem] object-cover bg-center bg-no-repeat`}
                        src={src}
                        onClick={() => openImageViewer(index)}
                        width="300"
                        key={index}
                        alt="photo_with_gd-holz_panels"
                    />))}
                    {isViewerOpen && (<ImageViewer
                        src={images}
                        currentIndex={currentImage}
                        disableScroll={false}
                        closeOnClickOutside={true}
                        onClose={closeImageViewer}
                    />)}
                </picture>
            </div>

            {/*     testimonials*/}
            <div id="testimoniale" className={`mx-auto  max-w-7xl py-16 `}>
                <h2 className={`text-center text-secondary font-bold text-4xl `}>Testimoniale</h2>
                <h3 className={`w-full px-4 s:px-6 sm:px-8 mx-auto text-center text-[#747984] mt-6 `}>Părerile
                    clienților noștri sunt foarte importante pentru noi.</h3>
                <div className={`pt-16`}>
                    <div className="mx-auto">
                        <Slider ref={setSliderRef}
                                centerMode={true}
                                infinite={false}
                                centerPadding={'60px'}
                                slidesToShow={1}
                                speed={500}
                                variableWidth={true}
                        >
                            {reviews?.map((review, i) => (<div key={i}
                                                               className={` relative reviewContainer  rounded-2xl shadow-[20px_35px_45px_-60px_rgba(5,5,70,0.6)] flex flex-col items-center text-center pt-8 pb-4 px-6`}>
                                <div className={`absolute top-10 sm:top-8 sm:left-8`}>
                                    <svg className={`w-10 sm:w-16`} viewBox="0 0 84 59" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M2.35626 30.0512C4.33822 19.6882 9.61463 8.70023 16.886 2.0195L21.4442 6.22711C20.3737 7.89916 19.1203 10.2485 18.2255 12.8289C17.2789 15.5587 16.6782 18.7049 17.2751 21.6248C17.5077 22.7628 18.4686 23.4464 19.4299 23.5566C22.5524 23.9146 26.7587 25.2172 29.8708 27.977C32.8959 30.6596 35.0039 34.8105 33.93 41.2534C32.4977 49.8475 28.3654 54.3699 23.5421 56.0555C18.6313 57.7715 12.6122 56.6888 7.21823 53.2366C4.60091 51.5615 2.85616 48.5095 2.03406 44.3984C1.21377 40.2963 1.35175 35.3034 2.35626 30.0512Z"
                                            stroke="#F3F3F3" stroke-width="3"/>
                                        <path
                                            d="M50.2664 30.0512C52.2484 19.6882 57.5248 8.70023 64.7961 2.0195L69.3544 6.22711C68.2838 7.89916 67.0305 10.2485 66.1356 12.8289C65.189 15.5587 64.5884 18.7049 65.1852 21.6248C65.4178 22.7628 66.3788 23.4464 67.34 23.5566C70.4625 23.9146 74.6689 25.2172 77.7809 27.977C80.806 30.6596 82.914 34.8105 81.8402 41.2534C80.4079 49.8475 76.2755 54.3699 71.4522 56.0555C66.5415 57.7715 60.5223 56.6888 55.1284 53.2366C52.5111 51.5615 50.7663 48.5095 49.9442 44.3984C49.1239 40.2963 49.2619 35.3034 50.2664 30.0512Z"
                                            stroke="#F3F3F3" stroke-width="3"/>
                                    </svg>
                                </div>
                                <Rating name="read-only" value={review.stars}
                                        precision={0.1} size="small" sx={{
                                    mx: "0"
                                }} readOnly/>
                                <h3 className={`font-bold text-sm`}>{review.name}</h3>
                                <p className={`mt-4 text-light-gray text-xs leading-relaxed`}>{review.content}</p>
                            </div>))}
                        </Slider>
                    </div>
                    <div className='mt-8 flex flex-row justify-center space-x-4'>
                        <button
                            className={`text-light-gray active:text-white rounded-full active:bg-[#000] w-10 h-10 flex flex-row items-center justify-center`}
                            onClick={sliderRef?.slickPrev}>
                            <FaChevronLeft className={`-ml-1`}/>
                        </button>
                        <button
                            className={`text-light-gray active:text-white rounded-full active:bg-[#000] w-10 h-10 flex flex-row items-center justify-center`}
                            onClick={sliderRef?.slickNext}>
                            <FaChevronRight className={`-mr-1`}/>
                        </button>
                    </div>
                </div>
            </div>
            <div className={`bg-[#EAEAEA] mx-8 lg:mx-16 my-6 h-[2px]`}></div>

            {/*   subscribe */}
            <div className={`w-full px-4 s:px-6 sm:px-8 lg:px-12 xl:px-16 max-w-7xl mx-auto`}>
                <div
                    className={`subscribeContainer rounded-2xl xl:rounded-3xl flex flex-col sm:flex-row justify-between items-center space-y-8 sm:space-y-0 py-6 sm:py-10 xl:py-12 px-4 sm:px-8 md:px-16 xl:px-20 mx-auto my-16 lg:my-28 max-w-3xl xl:max-w-4xl `}>
                    <div className={`sm:w-1/2`}>
                        <h2 className={`text-xl xl:text-2xl font-bold text-white`}>Subscribe newsletter</h2>
                        <h3 className={`text-xs xl:text-sm mt-4 leading-normal text-white w-4/5`}>Abonează-te pentru
                            a primi pe email ultimele noutăți!</h3>
                    </div>
                    <div>
                        <div
                            className={`border-transparent focus:border-transparent focus:ring-0  relative h-11 w-64 md:w-[330px] xl:w-[370px]`}>
                            <input
                                className={`border-transparent focus:border-transparent focus:ring-0  h-full w-full  rounded-xl  px-3 text-[11px] xl:text-xs text-light-gray`}
                                placeholder="Enter your email address .."
                                type="text"/>
                            <button
                                className={`absolute flex flex-row items-center justify-between px-2 top-0 right-0 rounded-r-xl  w-1/3 h-11  bg-[#171717]`}>
                                <h2 className={`text-white text-[11px] xl:text-xs ml-2`}>Descoperă</h2>
                                <div
                                    className={`hidden md:block flex flex-row items-center justify-center bg-white p-2 rounded-lg`}>
                                    <svg className={`w-2.5 h-2.5`} viewBox="0 0 11 14" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M0.748291 12.3492L6.49668 7L0.74829 1.65083L2.52233 4.01764e-07L10.0448 7L2.52233 14L0.748291 12.3492Z"
                                            fill="#171717"/>
                                    </svg>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>)
}