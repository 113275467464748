import React from 'react';
import ReactDOM from 'react-dom/client';
import Terms from './components/Terms';
import Politica from './components/Politica';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import './index.css';
import reportWebVitals from './reportWebVitals';
import Home from "./components/Home";
import ErrorPage from "./components/ErrorPage";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home />,
    },
    {
        path: "*",
        element: <ErrorPage />,
    },
    {
        path: '/acasa',
        element: <Home />,
    },
    {
        path: '/termeni-si-conditii' ,
        element: <Terms />,
       
    },
    {
        path: '/politica-si-confidentialitate',
        element: <Politica />,
        
    },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);
// ReactDOM.createRoot(document.getElementById("root")).render(
//     <React.StrictMode>
//       <RouterProvider router={router} />
//     </React.StrictMode>
// );

reportWebVitals();
